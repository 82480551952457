<template>
  <div>
    <h1>
      Notes
      <a
        href="javascript:;"
        class="float-right extreme-right"
        @click="addNote"
      >
        +
      </a>
    </h1>

    <div class="pane">
      <ul>
        <li
          v-for="note in notes"
          :key="note.id"
        >
          <router-link :to="`/notes/${note.id}`">
            {{ app.queries.noteTitle(note) }}
          </router-link>
        </li>
      </ul>
      <ul class="meta">
        <li>
          <router-link to="/storage">
            ⚙ Storage settings
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['app'],

  computed: {
    notes () {
      return this.app.queries.findAllNotes()
    }
  },

  methods: {
    addNote () {
      const note = this.app.commands.addNote({ content: '' })

      this.$router.push(`/notes/${note.id}`)
    }
  }
}
</script>
