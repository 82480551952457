<template>
  <div>
    <h1>
      <router-link
        to="/"
        class="float-left extreme-left"
      >
        &laquo;
      </router-link>
      Storage settings
    </h1>
    <StorageSettings
      :config="storageConfig"
      :defaults="storageDefaults"
      :save="saveStorage"
      class="pane with-padding"
    />
  </div>
</template>

<script>
import S3Adapter from '@/adapters/s3.js'
import StorageSettings from './storage-settings.vue'

export default {
  components: {
    StorageSettings
  },

  props: ['app'],

  data () {
    return {
      storageDefaults: {
        s3: S3Adapter.DEFAULTS
      }
    }
  },

  computed: {
    storageConfig () {
      return this.app.queries.getConfig()
    }
  },

  methods: {
    saveStorage (config) {
      return this.app.commands.saveStorageConfig(config)
        .then(() => this.app.commands.restoreRemote())
        .then(() => this.$router.push('/'))
        .catch((err) => alert(err.message))
    }
  }
}
</script>
