class Commands {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  seedNotes () {
    this.addNote({ content: 'This is the content!' })
  }

  addNote (data) {
    data = data || {}
    data.createdAt = Date.now()
    data.updatedAt = Date.now()

    const note = this.state.add('notes', [data])[0]

    this.saveNote(note)

    return note
  }

  addConfig (data) {
    return this.state.add('configs', [data || {}])[0]
  }

  removeNote (note) {
    if (confirm('Are you sure you wish to remove this note?')) {
      this.state.removeAll('notes', [note])
      this.removeLocalNote(note)
      this.removeRemoteNote(note)
    }
  }

  saveNote (note) {
    note.updatedAt = Date.now()

    this.saveLocalNote(note)
    this.saveRemoteNote(note)
  }

  saveLocalNote (note) {
    this.localAdapter.set(`notes-${note.id}`, note)
  }

  saveLocalConfig (config) {
    this.localAdapter.set(`configs-${config.id}`, config)
  }

  removeRemoteNote (note) {
    const config = this.queries.getConfig()

    if (config && config.type === 's3') {
      this.s3Adapter.remove(config, note.id)
    }
  }

  saveRemoteNote (note) {
    const config = this.queries.getConfig()
    const content = note.content

    if (config && config.type === 's3') {
      this.s3Adapter.set(config, note.id, content).then(() => {
        this.setRemoteCache(note.id, content)
      })
    }
  }

  updateNoteFromRemote (note) {
    const config = this.queries.getConfig()

    if (config && config.type === 's3') {
      return new Promise((resolve, reject) => {
        this.s3Adapter.fetch(config, note.id).then((data) => {
          if (data.updatedAt < note.updatedAt) {
            return resolve()
          }

          if (data.content !== note.content) {
            note.prevContent = note.content
          }

          note.updatedAt = data.updatedAt
          note.content = data.content

          this.saveLocalNote(note)
          this.setRemoteCache(note.id, data.content)

          resolve()
        }).catch(reject)
      })
    }
  }

  setRemoteCache (id, content) {
    const remoteNote = this.queries.findRemoteNote(id) || this.state.add('remoteNotes', [{ id }])[0]

    remoteNote.content = content
  }

  removeLocalNote (note) {
    this.localAdapter.remove(`notes-${note.id}`)
  }

  restoreLocal () {
    return new Promise((resolve, reject) => {
      this.localAdapter.restore().then((data) => {
        this.state.reset()

        this.state.add('notes', data.filter((record) => typeof record.content !== 'undefined'))
        this.state.add('configs', data.filter((record) => typeof record.type !== 'undefined'))

        resolve()
      }).catch(reject)
    })
  }

  restoreRemote () {
    return new Promise((resolve, reject) => {
      const config = this.queries.getConfig()
      const adapter = this[`${config.type}Adapter`]

      if (config.type === 'local') {
        return resolve()
      }

      if (!adapter) {
        return reject(new Error('No adapter found.'))
      }

      return adapter.restore(config).then((ids) => {
        let promises = []

        if (ids) {
          promises = ids
            .filter((id) => !this.queries.findNote(id))
            .map((id) => this.addRemoteNoteById(config, id))
        }

        Promise.all(promises).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  addRemoteNoteById (config, id) {
    return new Promise((resolve, reject) => {
      this.s3Adapter.fetch(config, id).then((data) => {
        const note = this.state.add('notes', [{
          id,
          content: data.content,
          createdAt: data.updatedAt || Date.now(),
          updatedAt: data.updatedAt || Date.now()
        }])[0]

        this.saveLocalNote(note)
        this.setRemoteCache(id, data.content)

        resolve(note)
      }).catch(reject)
    })
  }

  saveStorageConfig (data) {
    return new Promise((resolve, reject) => {
      const adapter = this[`${data.type}Adapter`]

      if (!adapter) {
        return reject(new Error('No adapter found.'))
      }

      adapter.validate(data)
        .then(() => {
          const config = this.queries.getConfig()

          for (const key in data) {
            config[key] = data[key]
          }

          this.saveLocalConfig(config)

          resolve()
        })
        .catch(reject)
    })
  }

  revertNote (note) {
    if (note.prevContent && confirm('Are you sure you want to revert to the last local content?')) {
      const oldContent = note.content

      note.content = note.prevContent
      note.prevContent = oldContent

      this.saveNote(note)
    }
  }

  storeLastPath (path) {
    this.localAdapter.set('lastPath', { path })
  }

  restoreLastPath () {
    return this.localAdapter.get('lastPath')
  }
}

export default Commands
