import AWS from 'aws-sdk'

const STRIP_BEGINNING_AND_END_SLASHES = /^\/|\/$/g

class S3 {
  validate (config) {
    return new Promise((resolve, reject) => {
      const s3 = this.buildS3(config)

      s3.listObjects({
        Bucket: config.bucket,
        MaxKeys: 1
      }, (err) => {
        if (err) {
          return reject(err)
        }

        resolve()
      })
    })
  }

  fetch (config, key) {
    return new Promise((resolve, reject) => {
      const s3 = this.buildS3(config)

      s3.getObject({
        Bucket: config.bucket,
        Key: this._prepKey(config.filename, key)
      }, (err, content) => {
        if (err) {
          return reject(err)
        } else if (!content || !content.Body) {
          return reject(new Error('No data returned'))
        }

        resolve({
          content: content.Body.toString(),
          updatedAt: content.LastModified.getTime()
        })
      })
    })
  }

  remove (config, key) {
    return new Promise((resolve, reject) => {
      const s3 = this.buildS3(config)

      s3.deleteObject({
        Bucket: config.bucket,
        Key: this._prepKey(config.filename, key)
      }, (err) => {
        if (err) {
          return reject(err)
        }

        resolve()
      })
    })
  }

  set (config, key, data) {
    const s3 = this.buildS3(config)
    const obj = {}

    for (const key in data) {
      obj[key] = data[key]
    }

    return new Promise((resolve, reject) => {
      s3.putObject({
        Body: typeof data === 'string' ? data : JSON.stringify(obj),
        Bucket: config.bucket,
        Key: this._prepKey(config.filename, key)
      }, function (err) {
        if (err) {
          return reject(err)
        }

        resolve()
      })
    })
  }

  restore (config) {
    return new Promise((resolve, reject) => {
      const s3 = this.buildS3(config)

      s3.listObjects({
        Bucket: config.bucket,
        MaxKeys: 1000,
        Prefix: config.filename || S3.DEFAULTS.key
      }, (err, data) => {
        if (err) {
          return reject(err)
        }

        resolve(data.Contents.map((c) => /(\w+)\.txt/.exec(c.Key)[1]))
      })
    })
  }

  buildS3 (data) {
    return new AWS.S3({
      endpoint: new AWS.Endpoint(data.endpoint || S3.DEFAULTS.endpoint),
      accessKeyId: data.accessKeyId,
      secretAccessKey: data.secretAccessKey,
      region: data.region || S3.DEFAULTS.region,
      apiVersion: 'latest',
      maxRetries: 1
    })
  }

  _prepKey (folder, key) {
    return (folder || S3.DEFAULTS.key).replace(STRIP_BEGINNING_AND_END_SLASHES, '') + '/' + key + '.txt'
  }
}

S3.DEFAULTS = {
  endpoint: 's3.us-east-1.amazonaws.com',
  key: 'notes',
  region: 'us-east-1'
}

export default S3
