<template>
  <div v-if="note">
    <h1>
      <router-link
        to="/"
        class="float-left extreme-left"
      >
        &laquo;
      </router-link>
      <a
        href="javascript:;"
        class="float-right extreme-right"
        @click="removeNote"
      >
        &times;
      </a>
      <a
        v-if="app.queries.isConfigRemote()"
        href="javascript:;"
        class="float-right"
        @click="app.commands.revertNote(note)"
      >
        <span v-if="app.queries.isNoteSynced(note)">🟢</span>
        <span v-else>🟡</span>
      </a>
    </h1>
    <textarea
      ref="textarea"
      v-model="note.content"
      class="mono pane with-padding"
      @change="app.commands.saveNote(note)"
      @keypress.enter="handleEnter"
      @keydown.tab="handleTab"
    />
  </div>
</template>

<script>
export default {
  props: ['app'],

  computed: {
    note () {
      return this.app.queries.findNote(this.$route.params.id)
    },

    isTouch () {
      try {
        document.createEvent('TouchEvent')
        return true
      } catch (e) {
        return false
      }
    }
  },

  mounted () {
    if (!this.note) {
      return this.$router.push('/')
    }

    if (!this.isTouch) {
      this.$refs.textarea.setSelectionRange(0, 0)
      this.$refs.textarea.focus()
    }

    this.app.commands.updateNoteFromRemote(this.note)

    window.addEventListener('focus', () => this.updateNoteFromRemote())
    window.addEventListener('blur', () => this.saveNote())
  },

  unmounted () {
    window.removeEventListener('focus', () => this.updateNoteFromRemote())
    window.addEventListener('blur', () => this.saveNote())
  },

  methods: {
    removeNote () {
      this.app.commands.removeNote(this.note)
      this.$router.push('/')
    },

    getLineBeginningIndex (val, selectionStart) {
      return val.slice(0, selectionStart - 1).lastIndexOf('\n') + 1
    },

    getLineFromIndex (val, selectionStart) {
      const lastLineBegins = this.getLineBeginningIndex(val, selectionStart)

      return val.slice(lastLineBegins, val.length).split('\n')[0]
    },

    handleTab (event) {
      const $textarea = event.target
      const val = $textarea.value
      const index = $textarea.selectionStart
      const line = this.getLineFromIndex(val, index)
      const lastLineBegins = this.getLineBeginningIndex(val, index)
      const check = /(\s+)?-\s/.exec(line)

      if (check) {
        if (event.shiftKey) {
          $textarea.value = `${val.substring(0, lastLineBegins)}${val.substring(lastLineBegins + 2, val.length)}`
          $textarea.selectionStart = index - 2
          $textarea.selectionEnd = index - 2
        } else {
          $textarea.value = `${val.substring(0, lastLineBegins)}  ${val.substring(lastLineBegins, val.length)}`
          $textarea.selectionStart = index + 2
          $textarea.selectionEnd = index + 2
        }
      }

      event.preventDefault()
    },

    handleEnter (event) {
      const $textarea = event.target
      const val = $textarea.value

      const index = $textarea.selectionStart
      if (index <= 0) {
        return
      }

      const line = this.getLineFromIndex(val, index)
      const check = /(\s+)?-\s/.exec(line)

      if (!check) {
        return
      }

      const listNewLine = `\n${check[1] || ''}- `

      $textarea.value = `${val.substring(0, index)}${listNewLine}${val.substring(index, val.length)}`
      $textarea.selectionStart = index + listNewLine.length
      $textarea.selectionEnd = index + listNewLine.length

      event.preventDefault()
    },

    saveNote () {
      if (this.note) {
        this.app.commands.saveNote(this.note)
      }
    },

    updateNoteFromRemote () {
      if (this.note) {
        this.app.commands.updateNoteFromRemote(this.note)
      }
    }
  }
}
</script>
