import { createRouter, createWebHashHistory } from 'vue-router'
import Notes from '@/app/views/notes/component.vue'
import Note from '@/app/views/note/component.vue'
import NoteStorage from '@/app/views/storage/component.vue'

const routes = [
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    alias: ['/']
  },
  {
    path: '/notes/:id',
    name: 'Note',
    component: Note
  },
  {
    path: '/storage',
    name: 'NoteStorage',
    component: NoteStorage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export {
  router,
  routes
}
