<template>
  <router-view
    v-if="!isLoading"
    :app="app"
    class="mono"
  />
</template>

<script>
import Commands from '@/commands/index.js'
import State from '@/state/index.js'
import Queries from '@/queries/index.js'
import LocalAdapter from '@/adapters/local.js'
import S3Adapter from '@/adapters/s3.js'

export default {
  props: {
    state: {
      type: Object,
      default () {
        return new State({
          notes: [],
          remoteNotes: [],
          configs: []
        })
      }
    },
    localAdapter: {
      type: Object,
      default: () => new LocalAdapter()
    },
    s3Adapter: {
      type: Object,
      default: () => new S3Adapter()
    }
  },

  data () {
    window.notes = this

    const queries = new Queries({
      state: this.state,
      localAdapter: this.localAdapter,
      s3Adapter: this.s3Adapter
    })

    const commands = new Commands({
      state: queries.state,
      queries,
      localAdapter: this.localAdapter,
      s3Adapter: this.s3Adapter
    })

    return {
      app: this,
      queries,
      commands,
      isLoading: true
    }
  },

  watch: {
    '$router.currentRoute.value.fullPath' () {
      this.app.commands.storeLastPath(this.$router.currentRoute.value.fullPath)
    }
  },

  mounted () {
    this.isLoading = true

    return this.app.commands.restoreLocal().then(() => {
      if (!this.app.queries.findAllNotes().length) {
        this.app.commands.seedNotes()
      }

      this.app.commands.restoreRemote()

      this.app.commands.restoreLastPath()
        .then((path) => {
          if (this.$router.currentRoute.value.name === 'Notes') {
            this.$router.push(path)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    })
  }
}
</script>

<style lang="scss">
@import "css/reset.scss";
@import "css/index.scss";
</style>
