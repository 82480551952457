<template>
  <form
    @submit.prevent="save(newConfig)"
  >
    <div class="field">
      <label>Where should your notes be stored?</label>
      <select v-model="newConfig.type">
        <option value="local">
          Only on this device
        </option>
        <option value="s3">
          S3
        </option>
      </select>
    </div>
    <div v-if="newConfig.type === 's3'">
      <div class="field">
        <label>Endpoint</label>
        <input
          v-model="newConfig.endpoint"
          type="text"
          :placeholder="_defaults.s3.endpoint"
        >
      </div>
      <div class="field">
        <label>Region</label>
        <input
          v-model="newConfig.region"
          type="text"
          :placeholder="_defaults.s3.region"
        >
      </div>
      <div class="field">
        <label>Access Key ID</label>
        <input
          v-model="newConfig.accessKeyId"
          type="text"
          required="true"
        >
      </div>
      <div class="field">
        <label>Secret Access Key</label>
        <input
          v-model="newConfig.secretAccessKey"
          type="password"
          required="true"
        >
      </div>
      <div class="field">
        <label>Bucket</label>
        <input
          v-model="newConfig.bucket"
          type="text"
          required="true"
        >
      </div>
      <div class="field">
        <label>Folder Path</label>
        <input
          v-model="newConfig.filename"
          type="text"
          :placeholder="_defaults.s3.filename"
        >
      </div>
    </div>
    <div class="action">
      <button type="submit">
        Save storage settings
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['defaults', 'save', 'config'],

  data () {
    return {
      newConfig: {}
    }
  },

  computed: {
    _defaults () {
      return this.defaults || {}
    }
  },

  mounted () {
    this.newConfig = Object.assign({}, this.config)
  }
}
</script>
