const SORT_BY_UPDATED_AT = (a, b) => {
  if (a.updatedAt < b.updatedAt) { return 1 }
  if (a.updatedAt > b.updatedAt) { return -1 }
  return 0
}

class Queries {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  findAllNotes () {
    return this.state.findAll('notes').sort(SORT_BY_UPDATED_AT)
  }

  findNote (id) {
    return this.state.findById('notes', id)
  }

  noteTitle (note) {
    return note.content.split(/\n/)[0]
  }

  getConfig () {
    return this.state.findAll('configs')[0] || this.state.add('configs', [{ type: 'local' }])[0]
  }

  findRemoteNote (id) {
    return this.state.findById('remoteNotes', id)
  }

  isNoteSynced (note) {
    const localNote = this.findNote(note.id)
    const remoteNote = this.findRemoteNote(note.id)

    return localNote && remoteNote && localNote.content === remoteNote.content
  }

  isConfigRemote () {
    return this.getConfig().type === 's3'
  }
}

export default Queries
